import React, { useMemo } from 'react';
import styled, {css} from 'styled-components';
import SbEditable from 'components/SbEditableExtended';

import ContentWrapper from 'components/contentWrapper';
import Grid from 'components/blocks/blockPartials/grid';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';


type Props = {
  block: any
};

// Component
const Component: React.FC<Props> = (props) => {

  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing])

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent {...props} className={`blockGrid ${spacingBottomClass}`}>

        <ContentWrapper fullWidthAt={[1,2]}>
          <Grid items={props.block.items} />
        </ContentWrapper>

      </StyledComponent>
    </SbEditable>
	)

}


export default Component;


// Styles
const StyledComponent = styled.div<Props>``;